import React from "react";
import NavigationBarLink from "./NavigationBarLink";

function NavigationBar() {
  return (
    <>
      <section className="fixed z-50 w-full px-8 text-gray-700 bg-white body-font">
        <div className="container flex items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
          <a
            href="/"
            className="relative z-10 flex items-center w-auto text-2xl font-extrabold leading-none text-black select-none w-1/3"
          >
            GymPass.
          </a>
          <nav className="top-0 left-0 z-0 flex items-center justify-center w-full h-full py-5 -ml-0 text-base space-x-5 md:-ml-5 md:py-0">
            <NavigationBarLink href="/" name="Home" />
            <NavigationBarLink href="/#features" name="Features" />
            <NavigationBarLink href="/blog" name="Blog" />
            <NavigationBarLink href="/team" name="Team" />
          </nav>
          <div className="relative z-10 inline-flex items-center space-x-3 md:ml-5 lg:justify-end w-1/2">
            <span className="inline-flex rounded-md shadow-sm">
              <a
                href="/contact"
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200"
              >
                Contact Us
              </a>
            </span>
            <span className="inline-flex rounded-md shadow-sm">
              <a
                href="/create"
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white whitespace-no-wrap bg-indigo-600 border border-blue-700 leading-6 rounded-md shadow-sm hover:bg-blue-700"
              >
                Get your FREE Design
              </a>
            </span>
          </div>
        </div>
      </section>
    </>
  );
}

export default NavigationBar;
