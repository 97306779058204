import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

function LeadGetter() {
  return (
    <section className="bg-white h-full pt-24">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-xl  h-full md:flex items-center">
        <div className="md:w-1/2">
          <img src="CALYPSO.png" className="" />
        </div>
        <div className="md:w-1/2">
          <h2 className="mb-1 text-3xl tracking-tight font-bold text-center text-gray-900 ">
            Get FREE design for your business
          </h2>
          <p className="mb-8 font-light text-center text-gray-500">
            Send link to company's website and we will design a pass for you
            based on it for <span className="font-semibold">FREE</span>. We will
            mail you your pass in less than{" "}
            <span className="font-semibold">24 hours</span>
          </p>
          <form action="https://formspree.io/f/xpzgobby" method="POST">
            <div className="mb-10">
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Company Link
              </label>
              <input
                type="text"
                id="subject"
                name="link"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500       "
                placeholder="https://example.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500       mb-3"
                placeholder="Your Name"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                E-Mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mb-12"
                placeholder="example@example.com"
                required
              />
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 flex"
            >
              Send me my pass
              <EnvelopeIcon className="w-5 h-5 ml-2" strokeWidth={2} />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default LeadGetter;
