import React from "react";

function Logo() {
  return (
    <div className="flex justify-center w-full">
      <a href="/" className=" text-2xl font-extrabold text-black mb-4">
        GymPass.
      </a>
    </div>
  );
}

export default Logo;
