import React from "react";

function ImageSection(props) {
  return (
    <section class="py-20 bg-white">
      <div class="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
        <img src={props.image} className="h-128 w-full rounded-xl" />
      </div>
    </section>
  );
}

export default ImageSection;
