import React from "react";

import {
  EnvelopeOpenIcon,
  PencilSquareIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

function Timeline() {
  return (
    <section class="px-2 py-32 bg-white md:px-0">
      <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900  md:text-4xl">
        Migrate in 3 easy steps
      </h2>
      <div class="container flex justify-center px-8 mx-auto xl:px-5">
        <ol class="items-center sm:flex">
          <li class="relative mb-6 sm:mb-0 text-center pr-6 max-w-sm">
            <a href="/create">
              <div className="flex justify-center">
                <PencilSquareIcon className="w-24 text-primary" />
              </div>
              <div class="mt-3">
                <h3 class="text-lg font-semibold text-gray-900 ">Design</h3>
                <p class="text-base font-normal text-gray-500 ">
                  Design your pass or let us design one for yourself. It is
                  totally free and takes only about 2-3 minutes.
                </p>
              </div>
            </a>
          </li>
          <li class="relative mb-6 sm:mb-0 text-center pr-6 max-w-sm">
            <a href="/create">
              <div className="flex justify-center">
                <TableCellsIcon className="w-24 text-primary" />
              </div>
              <div class="mt-3">
                <h3 class="text-lg font-semibold text-gray-900 ">
                  Import Data
                </h3>
                <p class="text-base font-normal text-gray-500 ">
                  We support every possible import option, spreadsheets,
                  databases, CSV files. We can import your data for you.
                </p>
              </div>
            </a>
          </li>
          <li class="relative mb-6 sm:mb-0 text-center pr-6 max-w-sm">
            <a href="/create">
              <div className="flex justify-center">
                <EnvelopeOpenIcon className="w-24 text-primary" />
              </div>
              <div class="mt-3">
                <h3 class="text-lg font-semibold text-gray-900 ">Distribute</h3>
                <p class="text-base font-normal text-gray-500 ">
                  We send out digital passes using our or yours email system,
                  sending personalized email with new pass to all your members.
                </p>
              </div>
            </a>
          </li>
        </ol>
      </div>
    </section>
  );
}

export default Timeline;
