import React from "react";

function FAQ() {
  return (
    <section class="relative py-48 bg-white min-w-screen animation-fade animation-delay tails-selected-element">
      <div class="container px-0 px-8 mx-auto sm:px-12 xl:px-5">
        <p
          class="text-xs font-bold text-left text-indigo-500 uppercase sm:mx-6 sm:text-center sm:text-normal sm:font-bold"
          data-primary="pink-600"
        >
          Got a Question? We’ve got answers.
        </p>
        <h3 class="mt-1 text-2xl font-bold text-left text-gray-800 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0">
          Frequently Asked Questions
        </h3>
        <div
          class="w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3"
          data-rounded="rounded-lg"
          data-rounded-max="rounded-full"
        >
          <h3
            class="text-lg font-bold text-indigo-600 sm:text-xl md:text-2xl"
            data-primary="purple-600"
          >
            What is a digital wallet pass?
          </h3>
          <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            A digital wallet pass is a digital representation of a card or
            ticket that can be stored in a smartphone's digital wallet app. It
            allows users to easily access and manage their membership, rewards,
            and other benefits from their mobile device.
          </p>
        </div>
        <div
          class="w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3"
          data-rounded="rounded-lg"
          data-rounded-max="rounded-full"
        >
          <h3
            class="text-lg font-bold text-indigo-600 sm:text-xl md:text-2xl"
            data-primary="purple-600"
          >
            How does a digital wallet pass work for gym membership?
          </h3>
          <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Once you have a digital wallet pass for your gym membership, you can
            present it at the gym's front desk for quick and easy check-in. Your
            pass will be scanned by the gym staff, and you'll be able to enter
            the gym without any additional steps.
          </p>
        </div>
        <div
          class="w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3"
          data-rounded="rounded-lg"
          data-rounded-max="rounded-full"
        >
          <h3
            class="text-lg font-bold text-indigo-600 sm:text-xl md:text-2xl"
            data-primary="purple-600"
          >
            Are digital wallet passes secure?
          </h3>
          <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Yes, digital wallet passes are highly secure. They use advanced
            encryption technology and are protected by a passcode or biometric
            authentication, such as Touch ID or Face ID, to prevent unauthorized
            access. In addition, digital wallet passes do not store any personal
            information, so your privacy is protected.
          </p>
        </div>
        <div
          class="w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3"
          data-rounded="rounded-lg"
          data-rounded-max="rounded-full"
        >
          <h3
            class="text-lg font-bold text-indigo-600 sm:text-xl md:text-2xl"
            data-primary="purple-600"
          >
            What devices can I use to store a digital wallet pass?
          </h3>
          <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Most smartphones have a built-in digital wallet app that can store
            digital wallet passes. This includes Apple Wallet on iOS devices and
            Google Pay on Android devices.
          </p>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
