import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

function Team() {
  return (
    <div>
      <NavigationBar />
      <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16 pt-16">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
              Our Team
            </h2>
            <p class="font-light text-gray-500 sm:text-xl">
              Meet the team behind our convenient access solution for gyms and
              fitness studios.
            </p>
          </div>
          <div class="sm:flex justify-center sm:space-x-24">
            <div class="text-center text-gray-500 ">
              <img
                class="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src="/ignacy.jpeg"
                alt="Ignacy Avatar"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900 ">
                <a href="#">Ignacy Wielogórski</a>
              </h3>
              <p className="w-48">Product Developer, Founder of GymPass</p>
            </div>
            <div class="text-center text-gray-500 ">
              <img
                class="mx-auto mb-4 w-36 h-36 rounded-full object-cover"
                src="/mateusz.jpg"
                alt="Ignacy Avatar"
              />
              <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900 ">
                <a href="#">Mateusz Piątkowski</a>
              </h3>
              <p className="w-48">Sales Manager, Head of Public Relations</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Team;
