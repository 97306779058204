import React from "react";

import NavigationBar from "./components/NavigationBar";
import HeroHome from "./components/HeroHome";
import Clients from "./components/Clients";
import Footer from "./components/Footer";
import HeroContent from "./components/HeroContent";
import CallToAction from "./components/CallToAction";
import Testimonials from "./components/Testimonials";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import FAQ from "./components/FAQ";
import ImageSection from "./components/ImageSection";
import Subscribe from "./components/Subscribe";
import CustomerLogosBar from "./components/CustomerLogosBar";
import Timeline from "./Timeline";
import Types from "./Types";

function Home() {
  return (
    <div className="">
      <NavigationBar />
      <HeroHome />
      {/* <CustomerLogosBar /> */}
      <ImageSection image="inside.jpg" />
      {/* <Clients /> */}
      {/* Migrate in 3 easy steps:
- Design
- Import / Input Data */}
      <CallToAction />
      <Timeline />
      <ImageSection image="inside.jpg" />
      <Features />
      <Types />
      <CallToAction />
      {/* <Testimonials /> */}
      {/* <HeroContent /> */}
      {/* <Pricing /> */}
      <FAQ />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Home;
