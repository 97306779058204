import React from "react";

function CallToAction() {
  return (
    <section class="py-20 bg-white tails-selected-element">
      <div class="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl md:text-5xl xl:text-6xl">
          Modern Gyms Standard
        </h2>
        <p class="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Make access to your gym more convenient, than ever allowing your
          client to store and retrieve their pass quickly in their's phone.
        </p>
        <div class="justify-center mt-8 space-x-3">
          <a
            href="/create"
            class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow hover:bg-indigo-700"
            data-primary="indigo-600"
            data-rounded="rounded-md"
          >
            Get your design
          </a>
          <p class="text-sm mt-2 text-gray-600">
            It only takes 2 minutes.{" "}
            <span className="font-bold">100% FREE</span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
