import React from "react";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import Subscribe from "../components/Subscribe";
import Author from "../components/Author";

function FutureArticle() {
  // TODO: Optimize loading of images
  return (
    <div>
      <NavigationBar />
      <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl mt-16">
          <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue space-y-5">
            <header class="mb-4 lg:mb-6 not-format">
              <Author
                img="/ignacy.jpeg"
                name="Ignacy Wielogórski"
                title="Web Developer, Founder & CEO of GymPass"
              />
              <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl ">
                The Future of Fitness: Emerging trends and how gym owners can
                adapt
              </h1>
            </header>
            <p class="lead">
              The fitness industry is rapidly evolving, and gym owners must be
              able to adapt to stay relevant and retain their members. In this
              article, we will explore some of the key trends in the fitness
              industry and highlight how gym owners can future-proof their
              businesses.
            </p>
            <img src="/inside.jpg" className="rounded-2xl"></img>
            <p>
              Technology Integration In recent years, technology has become
              increasingly important in the fitness industry. Gym owners can
              future-proof their businesses by embracing technology and
              integrating it into their operations. This could include offering
              mobile apps, implementing contactless payment systems, and using
              artificial intelligence to optimize member experiences.
            </p>
            <img src="/virtual.jpg" className="rounded-2xl"></img>
            <h1 className="text-2xl font-bold">Virtual Fitness</h1>
            <p>
              Virtual fitness has become increasingly popular, especially with
              the COVID-19 pandemic. Gym owners can future-proof their
              businesses by offering virtual fitness classes or partnering with
              online fitness programs. This will allow them to cater to members
              who prefer to work out from the comfort of their homes and expand
              their reach beyond their physical location.
            </p>
            <img src="/lift.jpg" className="rounded-2xl"></img>
            <h1 className="text-2xl font-bold">
              Personalized Training Programs{" "}
            </h1>
            <p>
              In today's fitness landscape, people are looking for customized
              training programs that cater to their specific needs and goals.
              Gym owners can future-proof their businesses by offering
              personalized training programs. This could include partnering with
              trainers who specialize in customized training or using artificial
              intelligence to tailor workouts to individual members.
            </p>
            <img src="/health.jpg" className="rounded-2xl"></img>
            <h1 className="text-2xl font-bold">Health and Wellness Services</h1>
            <p>
              Incorporating health and wellness services into a gym's offerings
              can help to future-proof the business. This could include offering
              nutritional counseling, wellness classes like yoga and meditation,
              or even partnering with medical professionals to provide
              specialized health services to members.
            </p>
            {/* // TODO: Change the name of that to something better */}
            <img src="/wallet.webp" className="rounded-2xl"></img>
            <h1 className="text-2xl font-bold">Digital Walker Passes</h1>
            <p>
              Digital Walker passes are another way that gym owners can
              future-proof their businesses. These passes allow members to
              access the gym using their smartphones, eliminating the need for
              physical membership cards. This technology not only streamlines
              operations but also provides a convenient and seamless experience
              for members.
            </p>
            <p>
              By embracing technology, offering personalized training programs,
              providing health and wellness services, and offering virtual
              fitness classes, gym owners can future-proof their businesses and
              stay ahead of the competition. And with digital Walker passes,
              they can streamline their operations and offer a more convenient
              experience for their members.
            </p>
            <p>
              If you're interested in learning more about how digital Walker
              passes can benefit your gym, or if you need help integrating other
              technology solutions into your gym, contact our team today. We can
              provide you with a customized solution that meets your needs and
              helps you stay ahead of the curve in the ever-changing fitness
              industry.
            </p>
          </article>
        </div>
      </main>

      {/* // TODO: Change this below */}
      {/* <aside aria-label="Related articles" class="py-8 lg:py-24 bg-gray-50 ">
        <div class="px-4 mx-auto max-w-screen-xl">
          <h2 class="mb-8 text-2xl font-bold text-gray-900 ">
            Related articles
          </h2>
          <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
            <article class="max-w-xs">
              <a href="#">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-1.png"
                  class="mb-5 rounded-lg"
                  alt="Image 1"
                />
              </a>
              <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 ">
                <a href="#">Our first office</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 ">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="#"
                class="inline-flex items-center font-medium underline underline-offset-4 text-indigo-600 "
              >
                Read in 2 minutes
              </a>
            </article>
            <article class="max-w-xs">
              <a href="#">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-2.png"
                  class="mb-5 rounded-lg"
                  alt="Image 2"
                />
              </a>
              <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 ">
                <a href="#">Enterprise design tips</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 ">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="#"
                class="inline-flex items-center font-medium underline underline-offset-4 text-indigo-600 "
              >
                Read in 12 minutes
              </a>
            </article>
            <article class="max-w-xs">
              <a href="#">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-3.png"
                  class="mb-5 rounded-lg"
                  alt="Image 3"
                />
              </a>
              <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 ">
                <a href="#">We partnered with Google</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 ">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="#"
                class="inline-flex items-center font-medium underline underline-offset-4 text-indigo-600 "
              >
                Read in 8 minutes
              </a>
            </article>
            <article class="max-w-xs">
              <a href="#">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-4.png"
                  class="mb-5 rounded-lg"
                  alt="Image 4"
                />
              </a>
              <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 ">
                <a href="#">Our first project with React</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 ">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="#"
                class="inline-flex items-center font-medium underline underline-offset-4 text-indigo-600 "
              >
                Read in 4 minutes
              </a>
            </article>
          </div>
        </div>
      </aside> */}

      <Subscribe />
      <Footer />
    </div>
  );
}

export default FutureArticle;
