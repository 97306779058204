import React, { useState } from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

function ErrorComponent({ error }) {
  return error ? <div className="text-red-500 pb-5">{error}</div> : null;
}

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    numClients: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    // Validate required fields
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^[0-9+\s]+$/.test(formData.phone)) {
      errors.phone = "Phone is invalid";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }

    // Set errors if any
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Proceed with form submission
    console.log("Form submitted:", formData);
    event.target.submit();
  };

  return (
    <div>
      <NavigationBar />
      <section className="px-2 py-32 bg-white md:px-0">
        <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div className="-mx-4 flex flex-wrap lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12 flex items-center">
              <div className="mb-12 max-w-[570px] lg:mb-0">
                <span className="text-primary mb-4 block text-base font-semibold">
                  Get design of your pass, 1 sample pass and consultation for
                  FREE
                </span>
                <h2 className="text-dark mb-6 text-[32px] font-bold sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                  We are Here to Help
                </h2>
                <p className="text-body-color mb-9 text-base leading-relaxed">
                  Please contact us, if you see a way in which we could help
                  your business with modernizing the entrance system.
                </p>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="relative rounded-lg bg-white p-8 shadow-lg sm:p-12">
                <form
                  action="https://submit-form.com/KarGkduz"
                  onSubmit={handleSubmit}
                >
                  <div className="mb-6">
                    <input
                      type="text"
                      name="name"
                      defaultValue={formData.name}
                      onChange={handleInputChange}
                      placeholder="Your Company's Name"
                      className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="email"
                      name="email"
                      defaultValue={formData.email}
                      onChange={handleInputChange}
                      placeholder="Your Email"
                      className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="text"
                      name="phone"
                      defaultValue={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Your Phone"
                      className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    />
                  </div>
                  <div className="mb-6">
                    <textarea
                      rows="6"
                      name="message"
                      defaultValue={formData.message}
                      onChange={handleInputChange}
                      placeholder="Your Message"
                      className="text-body-color border-[f0f0f0] focus:border-primary w-full resize-none rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                    ></textarea>
                  </div>
                  <ErrorComponent error={Object.values(errors)} />
                  <div>
                    <button
                      type="submit"
                      className="bg-primary border-primary w-full rounded-md border p-3 text-white transition hover:bg-opacity-90"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
