import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

function PageNotFoud() {
  return (
    <div>
      <NavigationBar />
      <div className="h-screen">
        <section class="bg-white h-full flex items-center">
          <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class="mx-auto max-w-screen-sm text-center">
              <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-indigo-600 ">
                404
              </h1>
              <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
                Something's missing.
              </p>
              <p class="mb-4 text-lg font-light text-gray-500 ">
                Sorry, we can't find that page. You'll find lots to explore on
                the home page.{" "}
              </p>
              <a
                href="/"
                class="inline-flex text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  my-4"
              >
                Back to Homepage
              </a>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default PageNotFoud;
