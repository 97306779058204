import React from "react";
import LeadGetter from "./components/LeadGetter";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

function Create() {
  return (
    <div className="w-screen">
      <NavigationBar />
      <LeadGetter />
      <Footer />
    </div>
  );
}

export default Create;
