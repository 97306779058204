import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Blog from "./Blog";
import Contact from "./Contact";
import Create from "./Create";
import Team from "./Team";
import SignUp from "./SignUp";
import Success from "./Success";
import Error from "./Error";
import LogIn from "./LogIn";
import FutureArticle from "./articles/FutureArticle";
import PageNotFound from "./PageNotFound";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/team" element={<Team />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/error" element={<Error />} />
        <Route path="/success" element={<Success />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/create" element={<Create />} />
        <Route path="/blog/thefutureoffitness" element={<FutureArticle />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
