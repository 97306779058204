import React from "react";

function NavigationBarLink(props) {
  return (
    <a
      href={props.href}
      className="relative font-medium leading-6 text-gray-600 transition duration-150 ease-out hover:text-gray-900"
      x-data="{ hover: false }"
    >
      <span className="block">{props.name}</span>
      <span className="absolute bottom-0 left-0 inline-block w-full h-0.5 -mb-1 overflow-hidden">
        <span
          x-show="hover"
          className="absolute inset-0 inline-block w-full h-1 h-full transform bg-gray-900"
          style={{ display: "none" }}
        />
      </span>
    </a>
  );
}

export default NavigationBarLink;
