import React from "react";

function Author({ img, name, date, title }) {
  return (
    <address class="flex items-center mb-6 not-italic">
      <div class="inline-flex items-center mr-3 text-sm text-gray-900 ">
        <img
          class="mr-4 w-16 h-16 rounded-full object-cover"
          src={img}
          alt={name}
        />
        <div>
          <a href="/team" rel="author" class="text-xl font-bold text-gray-900 ">
            {name}
          </a>
          <p class="text-base font-light text-gray-500 ">{title}</p>
          <p class="text-base font-light text-gray-500 ">
            <time pubdate datetime="2022-02-08" title="February 8th, 2022">
              {date}
            </time>
          </p>
        </div>
      </div>
    </address>
  );
}

export default Author;
