import React from "react";

function Types() {
  return (
    <section class="px-2 py-32 bg-white md:px-0">
      <div className="mb-8 lg:mb-16">
        <h2 className="mb-2 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900  md:text-4xl">
          We support all existing access technologies
        </h2>
        <p class="mt-2 text-lg text-center text-gray-600">
          No matter what technology you are using we guarantee you we can
          integrate with it.
        </p>
      </div>
      <div class="container flex justify-center items-center max-w-6xl px-8 mx-auto xl:px-5">
        <ol class="items-center sm:flex">
          <li class="relative mb-6 sm:mb-0 max-w-xs">
            <div className="h-72">
              <img src="/pkbarcode.png" className="max-w-xs"></img>
            </div>
            <h2 className="tracking-tight leading-tight text-center text-primary text-xl font-semibold">
              QR Codes
            </h2>
          </li>
          <li class="relative mb-6 sm:mb-0 max-w-xs">
            <div className="h-72 flex items-center">
              <img src="/barcode.png" className="max-w-xs"></img>
            </div>
            <h2 className="tracking-tight leading-tight text-center text-primary text-xl font-semibold">
              Barcode
            </h2>
          </li>
          <li class="relative mb-6 sm:mb-0 max-w-xs">
            <div className="h-72 flex items-center">
              <img src="/nfc.jpg" className="max-w-xs"></img>
            </div>
            <h2 className="tracking-tight leading-tight text-center text-primary text-xl font-semibold">
              NFC
            </h2>
          </li>
        </ol>
      </div>
    </section>
  );
}

export default Types;
