import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

function Success() {
  return (
    <div class="h-screen">
      <NavigationBar />
      <div className="h-full flex items-center justify-center">
        <section class="py-20 bg-white tails-selected-element">
          <div class="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl md:text-5xl xl:text-6xl">
              Your Account Has Been Created
            </h2>
            <p class="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Your API key, details and documentation have been sent to your
              email. If not please contact us.
            </p>
            <div class="flex justify-center mt-8 space-x-3">
              <a
                href="/contact"
                class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow hover:bg-indigo-700"
                data-primary="indigo-600"
                data-rounded="rounded-md"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Success;
